import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import EditIcon from "@mui/icons-material/Edit";
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import PopupDialog from "../components/PopupDialog";
import { API_CUSTOMERS, BASEURL } from "../context/ApiEndPoints";
import useFetchList from "../hooks/useFetchList";
import CustomerEditCard from "./CustomerEditCard";
import ButtonControl from "./controls/ButtonControl";

function CustomerCard(props) {
  const [popupDialog, setPopupDialog] = useState(false);
  const [popupContent, setPopupContent] = useState(null);
  const [isToggleEdit, setIsToggleEdit] = useState(false);

  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_CUSTOMERS}/Quote/${props.quoteId}`
  );
  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 3 }} />
        ))}
      {records && !isToggleEdit && (
        <>
          <Paper sx={{ p: 2 }}>
            <List
              subheader={
                <ListSubheader
                  component="div"
                  sx={{ color: "text.primary", fontSize: "large" }}
                >
                  Customer
                </ListSubheader>
              }
            >
              <Divider variant="middle" component="li" light />
              <ListItem
                secondaryAction={
                  <Tooltip title="Edit" placement="bottom" arrow>
                    <IconButton
                      color="primary"
                      aria-label="edit"
                      onClick={() => setIsToggleEdit(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                }
              >
                <ListItemText primary={records.email} secondary="Email" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.firstName}
                  secondary="First Name"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.lastName}
                  secondary="Last Name"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.phoneNumber}
                  secondary="Phone No."
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.address} secondary="Address" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.city} secondary="City" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.state} secondary="State" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.country} secondary="Country" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.zipCode} secondary="Zip Code" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.company} secondary="Company" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.taxNumber} secondary="Tax No." />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.whereFindUs}
                  secondary="Found Us"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  sx={{ whiteSpace: "pre-line" }}
                  primary={records.notes}
                  secondary="Notes (About)"
                />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.shop} secondary="Shop" />
              </ListItem>
              <ListItem>
                <ListItemText primary={records.user} secondary="User" />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.createdDate}
                  secondary="Created On Date"
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={records.modifiedDate}
                  secondary="Modified On Date"
                />
              </ListItem>
              <Divider />
              <ListItem>
                <Grid container spacing={1}>
                  {records.medias &&
                    records.medias.map((el, i) => (
                      <Grid item key={i}>
                        <Card>
                          <CardHeader
                            title={el.mime}
                            titleTypographyProps={{
                              fontSize: "small",
                            }}
                          />
                          {el.mime.includes("image") ? (
                            <>
                              <CardActionArea
                                onClick={() => {
                                  setPopupContent(
                                    `${BASEURL}/img/${el.fileName}`
                                  );
                                  setPopupDialog(true);
                                }}
                              >
                                <CardMedia
                                  component="img"
                                  height="144"
                                  image={`${BASEURL}/img/${el.fileName}`}
                                  alt={el.fileName}
                                  loading="lazy"
                                />
                              </CardActionArea>
                              <CardContent sx={{ textAlign: "center" }}>
                                <Typography variant="subtitle2" p={1}>
                                  ...
                                </Typography>
                                <Divider light />
                              </CardContent>
                            </>
                          ) : el.mime === "application/pdf" ? (
                            <CardContent sx={{ textAlign: "center" }}>
                              <embed
                                src={`${BASEURL}/img/${el.fileName}#view=FitV`}
                                type="application/pdf"
                                width="100%"
                                height="144"
                                loading="lazy"
                              />
                              <ButtonControl
                                component={Link}
                                href={`${BASEURL}/img/${el.fileName}`}
                                text="View"
                                variant="text"
                                size="medium"
                                startIcon={<DownloadRoundedIcon />}
                                download={el.fileName}
                                target="_blank"
                                sx={{ textTransform: "lowercase" }}
                              />
                              <Divider light />
                            </CardContent>
                          ) : (
                            <>
                              <CardMedia
                                component="img"
                                height="144"
                                image="img/file.png"
                                alt={el.fileName}
                                loading="lazy"
                              />
                              <CardContent sx={{ textAlign: "center" }}>
                                <ButtonControl
                                  component={Link}
                                  href={`${BASEURL}/img/${el.fileName}`}
                                  text="Download"
                                  variant="text"
                                  size="medium"
                                  startIcon={<DownloadRoundedIcon />}
                                  download={el.fileName}
                                  sx={{ textTransform: "lowercase" }}
                                />
                                <Divider light />
                              </CardContent>
                            </>
                          )}
                        </Card>
                      </Grid>
                    ))}
                </Grid>
              </ListItem>
            </List>
          </Paper>
          <PopupDialog
            popupDialog={popupDialog}
            setPopupDialog={setPopupDialog}
          >
            <img src={popupContent} alt={popupContent} />
          </PopupDialog>
        </>
      )}

      {records && isToggleEdit && (
        <Paper sx={{ p: 2 }}>
          <CustomerEditCard
            records={records}
            setRecords={setRecords}
            setIsToggleEdit={setIsToggleEdit}
          />
        </Paper>
      )}
    </>
  );
}

export default memo(CustomerCard);
