import SearchIcon from "@mui/icons-material/Search";
import TextsmsIcon from "@mui/icons-material/Textsms";
import {
  Avatar,
  Badge,
  CircularProgress,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from "@mui/material";
import React, { memo, Suspense, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ApiFetch from "../../components/ApiFetch";
import InputControl from "../../components/controls/InputControl";
import { API_CUSTOMERS, API_MSGS } from "../../context/ApiEndPoints";
import useAppContext from "../../hooks/useAppContext";
import useComponentContext from "../../hooks/useComponentContext";
import useFetchList from "../../hooks/useFetchList";
import { stringAvatar } from "../../scripts/Scripts";
// import MsgCustomerAdd from "./MsgCustomerAdd";
// import MsgDetails from "./MsgDetails";

const MsgCustomerAdd = React.lazy(() => import("./MsgCustomerAdd"));
const MsgDetails = React.lazy(() => import("./MsgDetails"));

function MsgQueryList(props) {
  const { user } = useAppContext();
  const { setNotify } = useComponentContext();

  const [selectedQuery, setSelectedQuery] = useState();
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isSaving, setIsSaving] = useState(false);

  const { records, setRecords, isLoading, fetchError } = useFetchList(
    `${API_MSGS}/Distinct`
  );

  const isGUID = (str) => {
    // Regex to check valid GUID
    let regex = new RegExp(
      /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/
    );
    // const emptyGUID = "00000000-0000-0000-0000-000000000000";

    // if str is empty return false
    if (str == null) return false;

    // Return true if the str matched the ReGex
    if (regex.test(str) === true) return true;
    else return false;
  };

  useEffect(() => {
    if (!isGUID(props.customerNumber) && records) {
      setSelectedQuery();

      const index = records.findIndex(
        (el) => el.customerNumber === props.customerNumber
      );
      setSelectedIndex(index);

      const item = records.find((el) => {
        return el.customerNumber === props.customerNumber;
      });
      setSelectedQuery(item);
    }

    return () => {};
    // eslint-disable-next-line
  }, [props.customerNumber, records]);

  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });

  const handleSearch = (e) => {
    let target = e.target;
    setFilterFn({
      fn: (items) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter((item) =>
            ["customerNumber"].some(
              (newitem) =>
                item[newitem]
                  .toString()
                  .toLowerCase()
                  .indexOf(target.value.toLowerCase()) > -1
            )
          );
        }
      },
    });
  };

  // const handleListClick = (item, i) => {
  //   if (item.customerNumber) {
  //     setSelectedQuery();
  //     setSelectedIndex(i);
  //     setSelectedQuery(item);
  //   }
  // };

  const handleAdd = async (record, resetForm) => {
    setIsSaving(true);
    //Create formData with file
    const formData = new FormData();
    for (const [k, v] of Object.entries(record)) {
      formData.append(k, v ?? "");
    }

    // Add customerFiles values to formData
    if (record.customerFiles) {
      for (const val of Object.values(record.customerFiles)) {
        formData.append("customerFiles", val);
      }
    }

    // POST request using fetch
    const requestOptions = {
      method: "POST",
      headers: { Authorization: "Bearer " + user.token },
      body: formData,
    };

    const results = await ApiFetch(
      API_CUSTOMERS,
      requestOptions,
      setNotify,
      "Customer Added Successfully!"
    );

    if (results) {
      // PUT request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: record.phoneNumber }),
      };

      const success = await ApiFetch(
        `${API_MSGS}/${results.customerId}`,
        requestOptions,
        setNotify,
        "Customer Added Successfully!"
      );

      if (success) {
        setSelectedIndex(-1);
        setRecords((records) =>
          records.filter((el) => el.customerNumber !== record.phoneNumber)
        );
        resetForm();
        setSelectedQuery();
      }
    }
    setIsSaving(false);
  };

  return (
    <>
      {fetchError ||
        (isLoading && (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ))}
      {records && (
        <Grid container spacing={1}>
          <Grid item xs={12} md={3}>
            <Paper square>
              <List>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <TextsmsIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary="Conversations" />
                </ListItem>
              </List>
              <form noValidate autoComplete="off">
                <InputControl
                  label="Search Query"
                  name="name"
                  type="search"
                  placeholder="Search Query By Phone Number"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  margin="none"
                />
              </form>
              <List disablePadding sx={{ height: "73vh", overflow: "auto" }}>
                {filterFn.fn(records).map((item, i) => (
                  <ListItem
                    key={i}
                    sx={{ borderBottom: 1, borderColor: "divider" }}
                    disablePadding
                    secondaryAction={
                      item.count > 0 && (
                        <Badge
                          badgeContent={item.count}
                          color="error"
                          sx={{ mr: 2 }}
                        />
                      )
                    }
                  >
                    <ListItemButton
                      selected={selectedIndex === i}
                      component={Link}
                      to={`/msgs/${item.customerNumber}`}
                      // onClick={(e) => handleListClick(item, i)}
                    >
                      <ListItemAvatar>
                        <Avatar>
                          <Avatar {...stringAvatar(item.customerNumber)} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.customerNumber} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            {selectedQuery && (
              <Suspense fallback={"Loading..."}>
                <MsgDetails selectedQuery={selectedQuery} />
              </Suspense>
            )}
          </Grid>
          <Grid item xs={12} md={3}>
            {selectedQuery?.customerNumber && (
              <Suspense fallback={"Loading..."}>
                <MsgCustomerAdd
                  selectedQuery={selectedQuery}
                  handleAdd={handleAdd}
                  isSaving={isSaving}
                />
              </Suspense>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default memo(MsgQueryList);
