import CheckIcon from "@mui/icons-material/Check";
import TextsmsIcon from "@mui/icons-material/Textsms";
import ThreePIcon from "@mui/icons-material/ThreeP";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_MSGS } from "../context/ApiEndPoints";
import useAppContext from "../hooks/useAppContext";
import useComponentContext from "../hooks/useComponentContext";
import ApiFetch from "./ApiFetch";

function MsgsHub() {
  const { user, msgAlerts, setMsgAlerts, msgAlertCount, setMsgAlertCount } =
    useAppContext();
  const { setNotify } = useComponentContext();
  const [isSaving, setIsSaving] = useState(false);
  let navigate = useNavigate();

  const handleDismiss = async (id, dismiss, url) => {
    if (!dismiss) {
      setIsSaving(true);

      // PUT request using fetch
      const requestOptions = {
        method: "PUT",
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": "application/json",
        },
      };
      const results = await ApiFetch(
        `${API_MSGS}/Dismiss/${id}`,
        requestOptions,
        setNotify
      );

      if (results) {
        const index = msgAlerts.findIndex((e) => e.msgId === id);
        msgAlerts[index] = results;
        setMsgAlerts(msgAlerts);
        setMsgAlertCount(msgAlertCount - 1);
      }
      setIsSaving(false);
    }
    if (!isSaving) navigate(url);
  };

  return (
    <Paper elevation={0} square>
      <List
        dense
        disablePadding
        sx={{ maxHeight: "40vh", overflowY: "auto" }}
        subheader={
          msgAlerts?.length === 0 && <ListSubheader>No records.</ListSubheader>
        }
      >
        {!msgAlerts ? (
          <CircularProgress size={20} color="inherit" sx={{ m: 2 }} />
        ) : (
          msgAlerts.map((it, i) => (
            <ListItem key={i} sx={{ borderBottom: 1, borderColor: "divider" }}>
              <ListItemIcon>
                {it.customerId === "00000000-0000-0000-0000-000000000000" ? (
                  <TextsmsIcon color={it.dismiss ? "default" : "error"} />
                ) : (
                  <ThreePIcon color={it.dismiss ? "default" : "error"} />
                )}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    display="inline-block"
                    color={it.dismiss ? "inherit" : "error"}
                    onClick={() =>
                      handleDismiss(
                        it.msgId,
                        it.dismiss,
                        `/msgs/${
                          it.customerId ===
                          "00000000-0000-0000-0000-000000000000"
                            ? it.customerNumber
                            : it.customerId
                        }`
                      )
                    }
                    sx={{ pr: 3, cursor: "pointer", whiteSpace: "pre-line" }}
                    disabled={isSaving}
                  >
                    {it.msgBody.length > 50
                      ? it.msgBody.substring(0, 50) + "..."
                      : it.msgBody}
                    <br />
                    {dayjs(it.createdDate).format("LT")}
                    {it.dismiss && (
                      <CheckIcon
                        fontSize="small"
                        sx={{ ml: 2, verticalAlign: "text-bottom" }}
                      />
                    )}
                  </Typography>
                }
                secondary={it.customerNumber}
              />
            </ListItem>
          ))
        )}
      </List>
    </Paper>
  );
}

export default memo(MsgsHub);
